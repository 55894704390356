import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";

const TemplateWrapper = ({ children, fullScreen, className }) => {
  const { title, description } = useSiteMetadata();

  let content;
  if (fullScreen) {
    content = children;
  } else {
    content = (
      <div className="container">
        <div className="site-content">
          <div className="columns is-gapless">
            <div className="nav-column column is-3 is-2-desktop is-3-fullhd">
              <Navbar />
            </div>
            <div className="column is-9 is-10-desktop is-10-fullhd">
              {children}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div className={className}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta
          name="viewport"
          content="height=device-height, 
                      width=device-width, initial-scale=1.0, 
                      minimum-scale=1.0, maximum-scale=1.0, 
                      user-scalable=no, target-densitydpi=device-dpi"
        ></meta>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/frank-masi_icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/frank-masi_icon.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/frank-masi_icon.png"
          sizes="16x16"
        />

        <link rel="mask-icon" href="/img/frank-masi_icon.png" color="#ff4400" />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      {content}
    </div>
  );
};

export default styled(TemplateWrapper)`
  & > .container {
    position: relative;
    padding-top: 31px;
  }
  & > .container > .site-content {
    display: flex;
    flex-direction: column;
    min-height: 74vh;
  }
  & > .container > .site-content > .columns > .column {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: 1024px) {
    .site-content > .columns {
      display: block;
      margin: 0;
    }
    .site-content > .columns > .column {
      width: 100%;
    }

    ${Navbar} .title.navbar-item {
      margin-bottom: 0;
    }
  }
`;
