import React from "react";
const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content">
          © Frank Masi 2019-2024, all images are subject to copyright and no
          reproductions shall be made wtihout the artists permission
        </div>
      </footer>
    );
  }
};

export default Footer;
