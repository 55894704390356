import styled from "styled-components";
import React from "react";

export const PageTitle = styled(({ children, className }) => (
  <h1 className={className}>{children}</h1>
))`
  font-size: 18px !important;
  font-weight: 500;
  text-align: center;
  margin: 34px 0;
  margin-bottom: 34px !important;
`;
