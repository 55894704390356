import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import styled from "styled-components";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  render() {
    let classes = classnames(this.props.className, "navbar is-transparent", {
      "nav-active": this.state.active
    });
    return (
      <nav className={classes} role="navigation" aria-label="main-navigation">
        <div className="">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item title" title="Logo">
              Frank
              <br />
              Masi
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-start">
              <Link className="navbar-item" to="/sets">
                image sets
              </Link>
              <Link className="navbar-item" to="/what-i-do">
                what I do
              </Link>
              <Link className="navbar-item" to="/art-reality-studio">
                art reality studio
              </Link>
              <Link className="navbar-item" to="/contact">
                contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default styled(Navbar)`
  font-size: 12px;
  .title.navbar-item {
    line-height: 1;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 71px;
  }
  .navbar-start {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    &.nav-active {
      /* position: absolute;
      background: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 31px; */
    }
  }
  [aria-current] {
    font-weight: 500;
  }
`;
